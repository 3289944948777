/** ngx global modal */
.modal-content {
	padding: 1.25rem;
	background: white;
	max-height: 100vh;
	overflow-y: auto;

  scrollbar-color: #a9a9a9 transparent;
	scrollbar-width: thin;
}
.modal-content h2 {
	margin-bottom: 3rem;
	font-weight: normal;
	text-align: center;
}
.modal-content p.third-p {
	margin-top: 1rem;
}
.actions {
	display: flex;
	justify-content: space-evenly;
}
/* .actions button {
  padding: 0.6rem 1.1rem;
  background: #e2e2e2;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
} */
.coming-from-caller {
	background: rgb(0, 100, 5);
	color: #fff;
	border-radius: 0.3rem;
	padding: 0.1rem 0.6rem;
	display: inline-block;
}
.coming-from-caller.no-data {
	padding: 0;
	background: none;
	color: #000;
}
