/* You can add global styles to this file, and also import other style files */
/* @use '@swimlane/ngx-datatable/index.scss';
@use '@swimlane/ngx-datatable/themes/material.scss';
@use '@swimlane/ngx-datatable/assets/icons.css'; */

//@use 'assets/scss/ngx-datatable.scss';
@use 'assets/scss/ngx-modal-ease.scss';
@use '@ng-select/ng-select/themes/default.theme.css';

@use 'ngx-toastr/toastr';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.badge-active {
		@apply bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300;
	}
	.badge-inactive {
		@apply bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300;
	}
	.btn-primary {
		@apply text-white items-center bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-600;
	}
	.btn-light {
		@apply text-sm font-medium bg-white rounded-lg border border-gray-300 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-50 hover:text-blue-600 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700;
	}
	.btn-danger {
		@apply text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800;
	}
	.btn-warning {
		@apply focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900;
	}
}

.hot-toast-close-btn {
	background-image: var(
		--hot-toast-close-btn-background-image,
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
	) !important;
}

.custom-scroll {
	scrollbar-color: #a9a9a9 transparent;
	scrollbar-width: thin;
}

.ng-select .ng-select-container {
	@apply border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-1 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	position: inherit !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	@apply bg-blue-200 border rounded;
}

.ng-select.ng-touched.customValidate .ng-select-container .ng-value-container .ng-placeholder {
	@apply text-red-900;
}

.datepicker.z-50 {
	z-index: 9999;
}

.modal-fixed-height {
	max-height: 70vh;
	scrollbar-width: thin;
}

.custom-toast.toast-success {
	color: #166534 !important;
	background-color: #f0fdf4 !important;
	background-image: url('assets/img/circle-check.svg');
	border: 1px solid #166534 !important;
}

.custom-toast.toast-error {
	color: #991b1b !important;
	background-color: #fef2f2 !important;
	background-image: url('assets/img/cloud-x.svg');
	border: 1px solid #991b1b !important;
}

.custom-toast.toast-info {
	color: #1d4ed8 !important;
	background-color: #eff6ff !important;
	background-image: url('assets/img/info-circle.svg');
	border: 1px solid #1d4ed8 !important;
}

.btn-pborder {
	padding: 0.56rem !important;
}

/** tables loader */
.loader {
	height: 6px;
	background-color: #bfdbfe;
	position: relative;
	overflow: hidden;
}

.loader::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 40%;
	height: 100%;
	background-color: #2563eb;
	animation: loading 1s infinite;
}

@keyframes loading {
	0% {
		left: 100%;
	}
	100% {
		left: -100%;
	}
}

.word-break {
	word-break: break-word;
}

/** start codigo para highlight.js */
pre {
	display: flex !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	word-wrap: break-word !important;
}

code {
	flex: 1 !important;
	line-height: 1.8em !important;
	font-size: 14px !important;
	min-height: 100% !important;
	padding: 1em 1.2em !important;
	overflow-x: unset !important;
	overflow-y: unset !important;
}

.hljs.hljs-line-numbers {
	padding: 0 !important;
}

pre .hljs {
	border: none !important;
	transition: border ease 1s !important;
	white-space: normal;
}

.hljs-ln {
	tr {
		&:first-child td {
			padding-top: 10px !important;
		}

		&:last-child td {
			padding-bottom: 10px !important;
		}
	}
}

/* for block of numbers */
td.hljs-ln-numbers {
	position: sticky;
	left: 0;
	user-select: none;
	text-align: center;
	color: #cccccc6b;
	border-right: 1px solid #cccccc1c;
	vertical-align: top;
	padding-right: 10px !important;
	padding-left: 10px !important;
	width: 4%;
}

/* for block of code */
td.hljs-ln-code {
	padding-left: 10px !important;
}
td .hljs-ln-n {
	width: fit-content;
}
table.hljs-ln {
	width: 100%;
	table-layout: fixed;
}
/** end codigo para highlight.js */
